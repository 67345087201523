import React from 'react';
import styles from './HowDoesItHelpMe.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBillTransfer, faPeopleArrowsLeftRight, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'

export default class HowDoesItHelpMe extends React.Component {
    render() {
        return <div className='container'>
            <h2 className='colorRed'>how does it help me</h2>

            <div className={styles.items}>
                <div className={`${styles.item}`}>
                    <FontAwesomeIcon className={`${styles.icon} ${styles.colorRed}`} icon={faMoneyBillTransfer} />
                    <h2>save <span class="colorRed">money</span></h2>
                    <h3>Lorem ipsum dolor sit  adipiscing elit, sed do eiusmod tempor incididunt ut labore  magna aliqua.</h3>
                </div>

                <div className={`${styles.item}`}>
                    <FontAwesomeIcon className={`${styles.icon} ${styles.colorRed}`} icon={faPeopleArrowsLeftRight} />
                    <h2>reach more <span class="colorRed">people</span></h2>
                    <h3>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3>
                </div>

                <div className={`${styles.item}`}>
                    <FontAwesomeIcon className={`${styles.icon} ${styles.colorRed}`} icon={faArrowTrendUp} />
                    <h2>push your <span class="colorRed">brand</span></h2>
                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit,  tempor incididunt ut  dolore magna aliqua.</h3>
                </div>
            </div>
        </div>;
    }
}