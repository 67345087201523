import React from 'react';
import styles from './IAmInterested.module.css'

export default class IAmInterested extends React.Component {
    render() {
        return <div className="container">
            <h2 className='colorRed'>I Am interested</h2>
            <h2>Tell us your email address so we can keep you updated</h2>
            <div className={styles.form}>
                <input placeholder='YOUR EMAIL ADDRESS' className={styles.input} type="text"></input>
                <button className={styles.button}>send</button>
            </div>
        </div>
    }
}