import React from 'react';
import './Nav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill1 } from '@fortawesome/free-solid-svg-icons'

export default class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: 'Yannik' };
    }

    render() {
        return <div className='container'>
            <div className='logo'>
                <FontAwesomeIcon className='icon colorRed' icon={faMoneyBill1} />
                <h2 className='text'>sell<span className='colorRed'>my</span>product<span className='colorRed'>.</span>de</h2>
            </div>
        </div>;
    }
}