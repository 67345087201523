import React from 'react';
import styles from './HowDoesItWork.module.css'

export default class HowDoesItWork extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                { index: 1, title: 'submit campaing', description: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
                { index: 2, title: 'review applications', description: 'Lorem ipsum dolor sit  adipiscing elit, sed do eiusmod tempor incididunt ut labore  magna aliqua.' },
                { index: 3, title: 'book influencers', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,  tempor incididunt ut.' },
            ],
        };
    }

    render() {
        return <div className='container'>
            <h2 className={styles.title} class="colorRed">How does it work</h2>

            <div className={styles.item}>
                <h2 className={styles.index}>1</h2>
                <div>
                    <h2 className={styles.title}>submit <span class="colorRed">campaing</span></h2>
                    <h3 className={styles.description}>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3>
                </div>
            </div>

            <div className={styles.item}>
                <h2 className={styles.index}>2</h2>
                <div>
                    <h2 className={styles.title}>review <span class="colorRed">applications</span></h2>
                    <h3 className={styles.description}>Lorem ipsum dolor sit  adipiscing elit, sed do eiusmod tempor incididunt ut labore  magna aliqua.</h3>
                </div>
            </div>

            <div className={styles.item}>
                <h2 className={styles.index}>3</h2>
                <div>
                    <h2 className={styles.title}>book <span class="colorRed">influencers</span></h2>
                    <h3 className={styles.description}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,  tempor incididunt ut.</h3>
                </div>
            </div>
        </div>;
    }
}