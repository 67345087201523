import React from 'react';
import './Hero.css'
import video from './assets/hero_video.mp4'
import neon from './assets/neon_highres.jpg'

export default class Hero extends React.Component {
	render() {
		return <div className='container'>
			<div className="content">
				<h1>
					Sell your <span className='colorRed'>product</span>
					<br></br>
					Reach more <span className='colorRed'>people</span>
				</h1>
				<h2>Sell your product using consumers.</h2>
			</div>

			<img src={neon} className="video"></img>


			{/* <video muted loop className='video'>
				<source src={video} type="video/mp4" />
			</video> */}
		</div>;
	}
}