import './App.css';
import Nav from './Nav'
import Hero from './Hero';
import HowDoesItWork from './HowDoesItWork';
import HowDoesItHelpMe from './HowDoesItHelpMe';
import IAmInterested from './IAmInterested';

function App() {
  return (
    <div className="App">
      <Nav></Nav>
      <Hero></Hero>
      <HowDoesItWork></HowDoesItWork>
      <HowDoesItHelpMe></HowDoesItHelpMe>
      <IAmInterested></IAmInterested>
    </div>
  );
}

export default App;
